<div class="height-full m-x modal">
  <div class="header-modal">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="w-100 center">
    <div class="w-80">
      <h2>Imágenes del banner</h2>
      @for (item of data.imgs; track item; let i = $index) {
      <div class="img-container-modal" [ngStyle]="getStylesImg(item)">
        <div class="icon-container">
          <label>
            <mat-icon>camera_alt</mat-icon>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              id="upload"
              style="display: none"
              (change)="fileChangeEvent($event, i)"
            />
          </label>
        </div>
      </div>
      }
    </div>
  </div>
</div>
