import { Component, Inject } from '@angular/core';
import { ModalData } from '../../dto/dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../../../shared/service/alert.service';
import { AuthService } from '../../../../shared/service/auth.service';
import { DataService } from '../../../../shared/service/data.service';
import { uploadData } from 'aws-amplify/storage';
import { environment } from '../../../../../environments/environment.development';
import { generateClient } from 'aws-amplify/api';
import { updateSetting } from '../../../../../graphql/mutations';
const client = generateClient();
@Component({
  selector: 'app-modal-banner',
  templateUrl: './modal-banner.component.html',
  styleUrl: './modal-banner.component.less',
})
export class ModalBannerComponent {
  constructor(
    public _dialogRef: MatDialogRef<ModalBannerComponent>,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private _dataService: DataService,
    private _alertService: AlertService
  ) {}
  close() {
    this._dialogRef.close();
  }
  getStylesImg(url: string) {
    const styles = {
      background: `url(${url}) center/cover no-repeat`,
    };
    return styles;
  }
  async fileChangeEvent(event, index: number) {
    try {
      this._dataService.displayLoading();
      const file = event.target.files[0];

      const date = new Date();
      const type = file.type.split('/')[1];
      const result = await uploadData({
        key: `${date.getTime()}.${type}`,
        data: file,
      }).result;

      this.data.imgs[index] = environment.urlBucket + result.key;
      await client.graphql({
        query: updateSetting,
        variables: {
          input: {
            id: this.data.id,
            imgs: this.data.imgs,
          },
        },
      });
      this._dataService.displayLoading(false);
    } catch (err) {
      this._alertService.openSnackBar(
        'Ha ocurrido un error al subir la imagen',
        'error'
      );
      this._dataService.displayLoading(false);
    }
  }
}
